<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $t('edit') }} {{ $tc('entity.user', 1) }}</span>
    </div>
    <el-form
      ref="form"
      v-loading="form.loading"
      :model="form.model"
      :rules="form.rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$tc('user', 1)" prop="userName">
            <el-input v-model="form.model.userName" :maxlength="24"></el-input>
          </el-form-item>
          <el-form-item :label="$t('email')" prop="userEmail">
            <el-input
              v-model="form.model.userEmail"
              :maxlength="124"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('name', 1)" prop="userFirstName">
            <el-input
              v-model="form.model.userFirstName"
              :maxlength="64"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('surname')" prop="userLastName">
            <el-input
              v-model="form.model.userLastName"
              :maxlength="64"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('phone')" prop="userPhone">
            <el-input v-model="form.model.userPhone" :maxlength="24"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ $tc('profile') }}</span>
              <el-button
                size="mini"
                style="float: right; padding: 3px 0"
                type="text"
                @click.prevent="onAddProfile"
              >
                {{ $tc('add') }}
              </el-button>
            </div>
            <transition-group name="fadeDown" tag="div">
              <el-row
                v-for="(item, index) in form.model.profiles"
                :key="`${index}-${item.roleId}`"
                :gutter="6"
              >
                <el-col :span="22">
                  <el-form-item>
                    <select-role
                      v-model="item.roleId"
                      :company-key="form.model.companyKey"
                      :label-all="false"
                      :service-id="$route.params.service"
                    ></select-role>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-button
                    size="mini"
                    type="danger "
                    icon="el-icon-delete"
                    circle
                    @click.prevent="onRemoveProfile"
                  ></el-button>
                </el-col>
              </el-row>
            </transition-group>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button
            v-if="roleIsSuperAdministrator"
            size="mini"
            type="primary"
            :loading="form.loading"
            @click="onUpdateChild"
          >
            {{ $t('save') }}
          </el-button>
          <router-link
            class="el-button el-button--text el-button--small"
            to="/users"
            tag="button"
          >
            {{ $t('cancel') }}
          </router-link>
        </el-form-item>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import form from '@/views/common/form'
import update from '@/views/common/update'
import { mapGetters } from 'vuex'
import model, { profileModel } from './model'
import rules from './rules'

export default {
  name: 'UserUpdate',
  extends: update('users', model),
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  methods: {
    onAddProfile() {
      this.form.model.profiles.push(profileModel)
    },
    onRemoveProfile(index) {
      this.form.model.profiles.splice(index, 1)
    },
    onUpdateChild() {
      this.onSubmit({
        action: `${this.$route.params.id}/${this.$route.params.company}/${this.$route.params.service}/hub`,
      })
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsSuperAdministrator: _this => [1].includes(+_this.setting.roleId),
  },
  mounted() {
    const _this = this
    _this.get({
      action: `${_this.$route.params.id}/${_this.$route.params.company}/${_this.$route.params.service}`,
    })
  },
}
</script>
