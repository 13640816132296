import { i18n } from '@/i18n'

export default {
  companyKey: [{ required: true, message: i18n.tc('required'), trigger: 'blur,change' }],
  serviceId: [{ required: true, message: i18n.tc('required'), trigger: 'blur,change' }],
  roleId: [{ required: true, message: i18n.tc('required'), trigger: 'blur,change' }],
  userName: [{ required: true, message: i18n.tc('required'), trigger: 'blur' }],
  userEmail:
    [
      { required: true, message: i18n.tc('required'), trigger: 'blur' },
      { type: 'email', message: i18n.tc('invalidEmail'), trigger: 'blur,change' },
    ],
  userFirstName: [{ required: true, message: i18n.tc('required'), trigger: 'blur' }],
  userLastName: [{ required: true, message: i18n.tc('required'), trigger: 'blur' }],
  userPhone: [{ required: true, message: i18n.tc('required'), trigger: 'blur' }],
}
