import { i18n } from '@/i18n'
import view from './view'

export default function (controller) {
  return {
    ...view,
    methods: {
      get(params) {
        const _this = this
        _this.form.loading = true
        _this.$http.get(!params || !params.action ? `${controller}/${_this.$route.params.id}` : `${controller}/${params.action}`)
          .then(response => {
            // _this.$set(_this.form.model, response.data, 0)
            _this.form.model = { ..._this.form.model, ...response.data }

            if (_this.onGetSuccess) {
              _this.onGetSuccess(response)
            }
          })
          .finally(() => { _this.form.loading = false })
      },
      async onSubmit(params) {
        const _this = this

        return new Promise((resolve, reject) => {
          _this.$refs.form.validate(async valid => {
            if (valid) {
              _this.form.loading = true

              await _this.$http.put(
                !params || !params.action
                  ? `${controller}/${_this.$route.params.id}`
                  : `${controller}/${params.action}`,
                _this.form.model,
              )
                .then(_ => {
                  _this.$message({ type: 'success', message: i18n.tc('processSuccess') })
                  _this.$emit('updated', _this.form.model)
                  resolve(_this.form.model)
                }, error => {
                  reject(error)
                }).finally(() => { _this.form.loading = false })
            } else {
              const message = 'Oops, hay datos requeridos que debes ingresar!'
              _this.$message.error(message)
              reject(message)
            }
          })
        }).then()
      },
      async onSubmitPartial({ action }) {
        const _this = this

        return new Promise((resolve, reject) => {
          _this.$refs.form.validate(async valid => {
            if (valid) {
              _this.form.loading = true

              await _this.$http.patch(`${controller}/${action}`, _this.form.model)
                .then(_ => {
                  _this.$message({ type: 'success', message: i18n.tc('processSuccess') })
                  _this.$emit('updated', _this.form.model)
                  resolve(_this.form.model)
                })

              _this.form.loading = false
            } else {
              const message = 'Oops, hay datos requeridos que debes ingresar!'
              _this.$message.error(message)
              reject(message)
            }
          })
        }).then()
      },
    },
  }
}
